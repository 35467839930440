var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-general',{ref:"sidebarCrearEntidad",attrs:{"title":"Crear entidad","aceptar":"Crear"},on:{"aceptar":_vm.crearEntidad}},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('b-avatar',{staticClass:"mr-2",attrs:{"rounded":"","size":"60"}})],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row pb-25"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","size":"sm"}},[_vm._v("Subir")])],1),_c('div',{staticClass:"row"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"}},[_vm._v("Borrar")])],1)])])]),_c('div',{staticClass:"mt-2"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Nombre"},model:{value:(_vm.params.nombre),callback:function ($$v) {_vm.$set(_vm.params, "nombre", $$v)},expression:"params.nombre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"descripcion"}},[_vm._v("Descripción")]),_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length ? false : null,"placeholder":"Descripción"},model:{value:(_vm.params.descripcion),callback:function ($$v) {_vm.$set(_vm.params, "descripcion", $$v)},expression:"params.descripcion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Estado")]),_c('ValidationProvider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.estados,"reduce":function (option) { return option.id; },"label":"name","placeholder":"Seleccionar"},on:{"input":_vm.onChangeState},model:{value:(_vm.params.id_state),callback:function ($$v) {_vm.$set(_vm.params, "id_state", $$v)},expression:"params.id_state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Ciudad")]),_c('ValidationProvider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.ciudades,"disabled":!_vm.params.id_state,"label":"name","reduce":function (option) { return option.id; },"placeholder":"Seleccionar"},model:{value:(_vm.params.id_city),callback:function ($$v) {_vm.$set(_vm.params, "id_city", $$v)},expression:"params.id_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }