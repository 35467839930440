<template>
    <sidebar-general
        ref="sidebarCrearEntidad"
        title="Crear entidad"
        aceptar="Crear"
        @aceptar="crearEntidad"
    >
        <ValidationObserver ref="form">
            <div class="container">
                <div class="mt-2">
                    <div class="row">
                        <div class="col-auto">
                            <b-avatar rounded size="60" class="mr-2"></b-avatar>
                        </div>
                        <div class="col">
                            <div class="row pb-25">
                                <b-button
                                    variant="success"
                                    size="sm"
                                    class="mr-1"
                                    >Subir</b-button
                                >
                            </div>
                            <div class="row">
                                <b-button variant="danger" size="sm"
                                    >Borrar</b-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <label for="nombre">Nombre</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="nombre"
                        rules="required|max:25"
                    >
                        <b-form-input
                            v-model="params.nombre"
                            :state="errors.length ? false : null"
                            placeholder="Nombre"
                        ></b-form-input>
                        <small class="text-danger"> {{ errors[0] }} </small>
                    </ValidationProvider>
                </div>
                <div class="mt-1">
                    <label for="descripcion">Descripción</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="descripcion"
                        rules="required|max:150"
                    >
                        <b-form-textarea
                            :state="errors.length ? false : null"
                            v-model="params.descripcion"
                            placeholder="Descripción"
                        ></b-form-textarea>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <div class="mt-1">
                    <label for="nombre">Estado</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="estado"
                        rules="required"
                    >
                        <v-select
                            v-model="params.id_state"
                            :options="estados"
                            :reduce="(option) => option.id"
                            label="name"
                            @input="onChangeState"
                            placeholder="Seleccionar"
                        ></v-select>
                        <small class="text-danger"> {{ errors[0] }} </small>
                    </ValidationProvider>
                </div>
                <div class="mt-1">
                    <label for="nombre">Ciudad</label>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="ciudad"
                        rules="required"
                    >
                        <v-select
                            v-model="params.id_city"
                            :options="ciudades"
                            :disabled="!params.id_state"
                            label="name"
                            :reduce="(option) => option.id"
                            placeholder="Seleccionar"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </sidebar-general>
</template>
<script>
import { ref, onMounted, reactive } from "@vue/composition-api";
import Entidades from "@/services/entidades";
import World from "@/services/world";
export default {
    setup(props, context) {
        const params = reactive({
            nombre: null,
            descripcion: null,
            id_city: null,
            id_state: null,
        });

        const estados = ref([]);
        const ciudades = ref([]);

        function toggle() {
            params.nombre = null;
            params.descripcion = null;
            params.id_city = null;
            params.id_state = null;
            fetchListarEstados();
            context.refs.sidebarCrearEntidad.toggle();
        }

        async function fetchCiudades() {
            try {
                const { data } = await World.listarCiudades(idState.value);
            } catch (error) {
                context.root.catch(error);
            }
        }
        async function fetchListarEstados() {
            try {
                const { data } = await World.listarEstados();
                estados.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function fetchListarCiudades(idState) {
            try {
                const params = {
                    id_state: idState,
                };
                const { data } = await World.listarCiudades(params);
                ciudades.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        function onChangeState(idState) {
            params.id_city = null;
            fetchListarCiudades(idState);
        }

        async function crearEntidad() {
            try {
                let validate = await context.refs.form.validate();

                if (!validate)
                    return context.root.notificacion(
                        "Por favor completa los campos",
                        null,
                        "warning"
                    );

                const { data } = await Entidades.crearEntidad(params);

                context.root.notificacion(data.message, null, "success");
            } catch (error) {
                context.root.catch(error);
            }
        }

        return {
            toggle,
            params,
            estados,
            ciudades,
            fetchListarCiudades,
            crearEntidad,
            onChangeState,
        };
    },
};
</script>
